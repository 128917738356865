<template>
    <div class="viewing_corridor">
        <taskCommon
            title="景观视线"
            :currentTree="currentTree"
            :list="list"
            :snSearch="snSearch"
            @handleChange="handleChange"
        >
            <slot>
                <Mymap
                    :mapData="mapData"
                    class="map"
                    ref="myMap"
                    @initmap="initMap"
                ></Mymap>
                <Dialog
                    ref="dialog"
                    :dialogData.sync="dialogData"
                    @saveSuccess="saveSuccess"
                ></Dialog>
            </slot>
        </taskCommon>
    </div>
</template>
<script>
import taskCommon from "../task-common";
import mixins from "../mixins";
import Dialog from "./viewing_corridor_dialog";
import { mapActions } from "vuex";
// require('mapbox-gl/dist/mapbox-gl.js');
// let mapboxGL = require('mapbox-gl-leaflet');
export default {
    name: "viewing_corridor",
    components: {
        taskCommon,
        Dialog,
    },
    mixins: [mixins],
    data() {
        return {
            mapData: {
                dxtState: true, //地形图
                maxZoom: 20,
                zoom: 12.5,
                areaState: true,
            },
            currentId: 90302,
            corridorMap: null,
            dataArr: [],
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        ...mapActions(["getVectorStyle", "getJbtuData"]),
        async initMap(map) {
            return;
            // 景观视线专题图
            let themeLayerStyle = await this.getVectorStyle({
                url: window.MAP_ZTT,
            });
            // eslint-disable-next-line
            let viewingCorridor = L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                maxZoom: 16,
                style: themeLayerStyle,
                interactive: true,
                dragRotate: false,
                dragPan: false,
                doubleClickZoom: false,
                touchZoomRotate: false,
            }).addTo(map);
            $(viewingCorridor._glContainer).addClass("viewingCorridor");
            let corridorMap = viewingCorridor.getMapboxMap();
            // marker点
            corridorMap.on("load", (e) => {
                let ary = corridorMap.queryRenderedFeatures();
                let configureData = ["E", "F", "G", "K"];
                ary.forEach((item) => {
                    if (
                        item.properties.code1 &&
                        configureData.includes(item.properties.code1)
                    ) {
                        // eslint-disable-next-line
                        L.marker(
                            [
                                (item.geometry.coordinates[1][1] -
                                    item.geometry.coordinates[0][1]) /
                                    2 +
                                    item.geometry.coordinates[0][1],
                                (item.geometry.coordinates[1][0] -
                                    item.geometry.coordinates[0][0]) /
                                    2 +
                                    item.geometry.coordinates[0][0],
                            ],
                            {
                                // eslint-disable-next-line
                                icon: L.icon({
                                    iconUrl: require("@image/map/icon/" +
                                        item.properties.code1 +
                                        ".png"),
                                    iconSize: [20, 20],
                                    iconAnchor: [10, 10],
                                }),
                            }
                        ).addTo(map);
                    }
                });
            });
            let popup = null;
            corridorMap.on("click", (e) => {
                let tilesAty = corridorMap.queryRenderedFeatures(e.point);
                if (tilesAty[0]) {
                    let code = tilesAty[0].properties.code1;
                    let data = [];
                    let coordinates = [];
                    if (popup) {
                        popup.remove();
                    }
                    this.list.map((item) => {
                        // 唯一方向
                        if (item.value === code && item.JCSJCOUNT) {
                            this.currentTree = item.value;
                            this.dialogData.title =
                                item.label + "-监测任务配置";
                            this.$refs.dialog.getDetail(this.currentTree);
                            this.dialogData.dialog = true;
                        } else if (
                            item.value.indexOf(code) !== -1 &&
                            item.JCSJCOUNT
                        ) {
                            // 相互方向
                            coordinates = [
                                (tilesAty[0].geometry.coordinates[1][1] -
                                    tilesAty[0].geometry.coordinates[0][1]) /
                                    2 +
                                    tilesAty[0].geometry.coordinates[0][1],
                                (tilesAty[0].geometry.coordinates[1][0] -
                                    tilesAty[0].geometry.coordinates[0][0]) /
                                    2 +
                                    tilesAty[0].geometry.coordinates[0][0],
                            ];
                            data.push(item);
                        }
                        return null;
                    });
                    // 相互方向
                    if (data.length) {
                        let content = data
                            .map(
                                (item, index) =>
                                    `<div class="content_item" id="corridor_pop${index}">${item.label}</div>`
                            )
                            .join("");
                        // eslint-disable-next-line
                        popup = L.popup({
                            closeOnClick: false,
                            closeButton: false,
                            offset: [0, 5],
                            minWidth: 12,
                        })
                            .setLatLng(coordinates)
                            .setContent(content)
                            .openOn(map);
                        data.forEach((item, index) => {
                            $("#corridor_pop" + index).click((e) => {
                                if ($(".content_item").hasClass("active")) {
                                    $(".content_item").removeClass("active");
                                }
                                $("#corridor_pop" + index).addClass("active");
                                this.currentTree = item.value;
                                this.dialogData.title =
                                    item.label + "-监测任务配置";
                                this.$refs.dialog.getDetail(this.currentTree);
                                this.dialogData.dialog = true;
                            });
                        });
                    }
                }
            });
        },
        async getList() {
            let result = await this.getJbtuData({
                id: "90301",
            });
            this.list = result
                .map((item) => {
                    return {
                        value: item.Id,
                        // label: item.Id + item.Mc,
                        label: item.Mc,
                        JCSJCOUNT: !item.Zb && !item.Fx ? 0 : 1,
                        JCPZCOUNT: item.JCPZCOUNT,
                    };
                })
                .sort((item1, item2) => {
                    return item2.JCSJCOUNT - item1.JCSJCOUNT;
                });
        },
        changeList(val) {
            console.log(val);
            if (this.list[val[2]].JCSJCOUNT) {
                this.currentTree = val[0];
                this.dialogData.title = val[1] + "-监测任务配置";
                this.$refs.dialog.getDetail(this.currentTree);
                this.dialogData.dialog = true;
            }
        },
        // 保存成功后 重新获得列表
        async saveSuccess() {
            await this.getList();
        },
    },
};
</script>
<style lang="scss" scoped>
.viewing_corridor {
}
</style>